import { get, isNumber, pick, toNumber } from "lodash";

import { PackageFormDataType, PackageType } from "@lib/data/schemas/packages";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import { countDiffDays, weekdays, WeekdayType } from "@lib/packages";

/**
 * The react hook forms does not deal well with numeric values, and in some
 * cases, it returns the values as strings. This function will fix the numeric
 * from the cases we know it will be a number to send to the API.
 * */
export const fixUsageInvoicingNumericValues = (data: PackageFormDataType) => {
  if (data?.usageInvoicing?.autoChargeDay) {
    const isMonthly = data?.usageInvoicing?.period === "months";
    if (isMonthly) {
      data.usageInvoicing.autoChargeDay = Number(
        data.usageInvoicing.autoChargeDay
      );
    } else {
      const value = countDiffDays(
        (data?.usageInvoicing?.cutOffDate as WeekdayType) ?? "monday",
        (data?.usageInvoicing?.autoChargeDay as WeekdayType) ?? "monday"
      );
      data.usageInvoicing.autoChargeDay = value;
    }
  }
  if (data?.usageInvoicing?.subscriptionAmount) {
    data.usageInvoicing.subscriptionAmount = Number(
      data.usageInvoicing.subscriptionAmount
    );
  }
  return data;
};

/**
 * Find the auto charge weekday value based on the cut off date, and the
 * value saved as numeric value The value stored is the sum of days based
 * on the cut off date and the auto charge day.
 * */
export const findAutoChargeWeekdayValue = (
  cutOffDate: string,
  autoChargeDay: number
) => {
  const cutOffDayIndex = weekdays.indexOf(cutOffDate);
  const value = cutOffDayIndex + autoChargeDay;
  const index = value >= 7 ? value - 7 : value;
  return weekdays[index];
};

/**
 * Transform data in databas to be used in input form.
 * React rook form does change the value of input to string or number
 * when we work with array lists. So we need to transform the data
 * to pass in the schema validation.
 * */
export const transformFormItemsData = (
  data: PackageType | PackageFormDataType
) => {
  return (data.items || []).map((item) => ({
    ...item,
    quantity: isNumber(item.quantity)
      ? (item.quantity as number).toString()
      : item.quantity,
  }));
};

/**
 * It converts the quantity to number when it is a string. This is necessary
 * to send data to the API, that expects numbers, and the react-hook-form
 * package always convert numeric values to strings when it's used as an
 * array field
 * */
export const transformFormItemsDataToNumber = (
  data: PackageType | PackageFormDataType
) => {
  return (data.items || []).map((item) => ({
    ...item,
    quantity: isNumber(item.quantity) ? item.quantity : toNumber(item.quantity),
  }));
};

/**
 * Transform data in databas to be used in input form
 * */
const transformInputForm = (
  data: PackageType,
  schedulers: SchedulerType[]
): PackageFormDataType => {
  const allItems = transformFormItemsData(data);
  const allSchedulerIds = schedulers.map(({ id }) => id);
  const items = allItems.filter(({ schedulerId }) =>
    allSchedulerIds.includes(schedulerId)
  );

  const paymentOptions = (get(data, "paymentOptions") || []).map(
    (paymentOption) => ({
      ...paymentOption,
      amount: (paymentOption.amount / 100).toString(),
    })
  );
  const packageType = get(data, "packageType");

  const currentUsageInvoicing = get(data, "usageInvoicing");
  const usageInvoicing = currentUsageInvoicing
    ? currentUsageInvoicing?.period === "weeks"
      ? {
          ...currentUsageInvoicing,
          autoChargeDay: findAutoChargeWeekdayValue(
            currentUsageInvoicing.cutOffDate!,
            currentUsageInvoicing.autoChargeDay!
          ),
        }
      : currentUsageInvoicing
    : null;

  const serviceFee = data?.serviceFee
    ? {
        ...data.serviceFee,
        enabled: true,
        agreedToTerms: true,
      }
    : null;

  return {
    ...pick(data, [
      "title",
      "internalName",
      "slug",
      "description",
      "icon",
      "distributeSessions",
      "totalSessions",
      "contentType",
      "timeType",
      "autocomplete",
      "frequency",
      "rollOver",
      "billingRateId",
      "acceptedOutcomes",
      "autoCancelOutcome",
      "packageBillingRateType",
      "locked",
      "depositRule",
      "onTrackNotifications",
      "onTrackRules",
      "tiers",
      "earliestStartDate",
    ]),
    items: items,
    paymentOptions,
    packageType: packageType ?? "one-time",
    // @TODO: remove data?.smartActionTemplates when we start QA the package
    //        subscription payment project
    smartActions: data?.smartActionTemplates || data?.smartActions || [],
    allowFutureBooking: !!data?.allowFutureBooking,
    usageInvoicing: usageInvoicing ?? null,
    coupons: data?.couponIds?.map((id) => ({ value: id })) ?? [],
    onTrackEnabled: !!data?.onTrackRules,
    enableAtRisk: !!data?.onTrackRules?.atRiskSessionCount,
    serviceFee,
  };
};

export default transformInputForm;
